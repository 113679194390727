import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { connect } from "react-redux";
import ReactBody from "react-body";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="content">
      <h1>NOT FOUND</h1>
      <p>Sorry, we can't find this page!</p>
    </div>
    <ReactBody className="error" />
  </Layout>
)

const mapStateToProps = ({ language }) => {
  return { language }
};

export default connect(mapStateToProps)(NotFoundPage);
